import React from "react";
import { Link } from "react-router-dom";
import transportCover from "../assets/img/content/transportCover.png";
import retail from "../assets/img/content/retail.png";
import ecommSolution from "../assets/img/content/ecomSolution.png";
import Navbar2 from "./Navbar2";


function  ECommerce() {
  return (
    <div>
      <Navbar2 />
      <section
        className="page-title-section2 bg-img cover-background "
        data-overlay-dark={1}
        style={{ backgroundImage: `url(${transportCover})` }}
      >
        <div className="container">
          <h1 className="text-center mt-n7 ">
            Smart Retail: Operations and Customer Experience with Cloud
            Solutions
          </h1>
          <div className="row">
            <div className="col-md-12">
              <h1></h1>
            </div>
            <div className="col-md-12 ">
              <ul className="ps-0 ">
                <li>
                  <Link to="/industries" className="fs-3">
                    Industries
                  </Link>
                </li>
                <li>
                  <Link to="#!" className="fs-3">
                    E-Commerce
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="p-md-4 p-0 text-center ">
        <div className="container ">
          <h1 className="">
            Cloud-Powered Retail Transformation: Efficiency and Customer
            Experience
          </h1>
          <p className="my-lg-4 my-2  fs-5">
            Our Cloud Solutions for Retail, where innovation meets efficiency.
            Discover how our comprehensive suite of cloud-based tools optimizes
            inventory management, enhances the customer experience across
            multiple channels, and provides valuable data insights to drive
            strategic decision-making in the ever-evolving retail landscape.
          </p>
        </div>
      </section>

      <section className="p-3 p-lg-6 bg-light m-2 m-lg-5">
        <div className="container text-center">
          <img src={retail} alt="" className="img-fluid rounded" />
        </div>

        <div className="container mt-4 mt-lg-6">
          <hr className="w-100 text-success" />
          <div className="row">
            <div className=" col-lg-6 mt-3">
              <h4>Real-time Inventory Visibility</h4>
              <p className="fs-5 ">
                Cloud-based solutions provide retailers with real-time
                visibility into their inventory across multiple locations and
                channels. This enables accurate stock monitoring, reduces
                out-of-stock situations, and improves overall inventory
                management.
              </p>
            </div>
            <div className="mt-3 col-lg-6 ">
              <h4>Demand Forecasting and Planning</h4>
              <p className="fs-5">
                By leveraging cloud-based analytics, retailers can analyze
                historical sales data and market trends to forecast demand
                accurately. This helps optimize inventory levels, reduce
                overstocking or understocking, and improve supply chain
                efficiency.
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-4 mt-lg-6">
          <hr className="w-100 text-success" />
          <div className="row">
            <div className=" mt-3 col-lg-6 ">
              <h4>Inventory Replenishment Automation</h4>
              <p className="fs-5">
                Cloud-based systems can automate inventory replenishment
                processes, triggering purchase orders or reordering products
                when stock levels reach predefined thresholds. This streamlines
                operations, ensures product availability, and reduces manual
                effort.
              </p>
            </div>
            <div className=" mt-3 col-lg-6 ">
              <h4>Vendor Collaboration and Supplier Management</h4>
              <p className="fs-5">
                Cloud-based collaboration platforms enable seamless
                communication and collaboration between retailers and their
                suppliers. This enhances supply chain visibility, improves order
                fulfillment, and strengthens supplier relationships.
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-4 mt-lg-6">
          <hr className="w-100 text-success" />
          <div className="row">
            <div className=" mt-3 col-lg-6 ">
              <h4>E-commerce Platform Integration</h4>
              <p className="fs-5">
                Cloud-based solutions facilitate the integration of e-commerce
                platforms with existing retail systems. This enables retailers
                to provide a seamless shopping experience across online and
                offline channels, synchronize inventory, and streamline order
                fulfillment.
              </p>
            </div>
            <div className=" mt-3 col-lg-6 ">
              <h4>Personalized Customer Experience</h4>
              <p className="fs-5">
                Cloud-based customer relationship management (CRM) systems
                capture and analyze customer data to provide personalized
                shopping experiences. This includes personalized
                recommendations, targeted promotions, and tailored marketing
                campaigns.
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-4 mt-lg-6">
          <hr className="w-100 text-success" />
          <div className="row">
            <div className=" mt-3 col-lg-6 ">
              <h4>Order Management and Fulfillment</h4>
              <p className="fs-5">
                Cloud-based order management systems enable efficient order
                processing, fulfillment, and tracking across multiple channels.
                This ensures accurate and timely delivery, improves customer
                satisfaction, and reduces fulfillment errors.
              </p>
            </div>
            <div className=" mt-3 col-lg-6 ">
              <h4>Click-and-Collect and Ship-from-Store</h4>
              <p className="fs-5">
                Cloud-based solutions enable retailers to offer convenient
                click-and-collect and ship-from-store options. This optimizes
                inventory utilization, reduces delivery costs, and enhances
                customer convenience and satisfaction.
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-4 mt-lg-6">
          <hr className="w-100 text-success" />
          <div className="row">
            <div className=" mt-3 col-lg-6 ">
              <h4>Sales Analytics and Reporting</h4>
              <p className="fs-5">
                Cloud-based analytics platforms consolidate and analyze sales
                data from multiple sources, providing retailers with actionable
                insights. This includes sales performance analysis, product
                performance evaluation, and store-level analytics.
              </p>
            </div>
            <div className=" mt-3 col-lg-6 ">
              <h4>Customer Analytics and Segmentation</h4>
              <p className="fs-5">
                Cloud-based CRM systems leverage customer data to generate
                insights and segment customers based on their preferences,
                behavior, and purchase history. This enables targeted marketing
                campaigns and personalized customer experiences.
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-4 mt-lg-6">
          <hr className="w-100 text-success" />
          <div className="row">
            <div className=" mt-3 col-lg-6 ">
              <h4>Pricing Optimization</h4>
              <p className="fs-5">
                Cloud-based pricing analytics solutions help retailers optimize
                pricing strategies based on market dynamics, competitor pricing,
                and customer demand. This allows for dynamic pricing
                adjustments, promotional pricing optimization, and improved
                profitability.
              </p>
            </div>
            <div className=" mt-3 col-lg-6 ">
              <h4>Fraud Detection and Prevention</h4>
              <p className="fs-5">
                Cloud-based fraud detection systems utilize advanced analytics
                and machine learning algorithms to identify and prevent
                fraudulent activities in retail transactions. This safeguards
                retailers against financial losses and protects customer data.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="p-3 p-lg-8">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 text-lg-start">
              <h5 className="text-muted">The Solution:</h5>
              <h1 className="fw-bold">
                Retail Excellence with Cloud Solutions
              </h1>
              <p className="h5 text-muted">
                Streamlining Operations, Enhancing Customer Experience, and
                Unleashing Data Insights
              </p>
              <p className="mt-3 fs-5">
                Cloud solutions have become instrumental in revolutionizing the
                retail industry, offering a diverse array of tools to overcome
                critical challenges and drive operational efficiency. By
                leveraging cloud-based platforms, retailers gain real-time
                visibility into their inventory across multiple locations and
                channels, enabling accurate stock monitoring and reducing
                out-of-stock situations.
              </p>
              <p className="fs-5">
                Cloud-based analytics provide retailers with valuable insights,
                from demand forecasting to customer segmentation, helping them
                optimize inventory levels, personalize customer experiences, and
                design effective marketing campaigns. Integration of e-commerce
                platforms with cloud solutions allows retailers to offer a
                seamless omnichannel experience, ensuring smooth order
                management and fulfillment.
              </p>
              <p className="fs-5">
                Moreover, cloud-based fraud detection systems safeguard
                retailers against financial losses and protect customer data.
                With cloud solutions, the retail industry is equipped to create
                seamless shopping experiences, boost profitability, and stay
                competitive in the ever-evolving marketplace.
              </p>
            </div>

            <div className="col-lg-6 mb-lg-20 text-center text-lg-start">
              <img
                src={ecommSolution}
                alt="Cloud Solutions for Retail"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container p-2">
        <div className="container">
          <h1 className="fw-normal">
            Need Expert Help to Estimate Your App Modernization Costs?
          </h1>
          <h5 className="fw-normal my-4 fs-5 ">
            Choosing Infysion as your partner, Our architects and business
            analysts are ready to transform legacy app modernization services
            for your specific case and help you and Step into the Future with
            Legacy Application Modernization Solutions.
          </h5>
        </div>

        <div className="container mt-8  pb-2">
          <h4 className="text-center  text-uppercase my-5">Reach Out Now!</h4>
          <div className="row  p-3">
            <div className="col-lg-6 order-2 order-lg-1 border border-2 border-gray rounded px-0 ">
              <iframe
                className="contact-map rounded"
                id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60541.903684004654!2d73.853951!3d18.489593!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1982877f07b%3A0x1627e42ae3e8b0c2!2sShekru%20labs%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1721196205198!5m2!1sen!2sin"
              />
            </div>

            <div className="col-lg-6 order-1 order-lg-2 ">
              <div className="contact-info ps-lg-1-9 mb-1-9 mb-lg-0">
                <div className="item bg-white">
                  <span className="icon">
                    <i className="fas fa-phone text-primary" />
                  </span>
                  <div className="cont">
                    <h6 className="mb-1 font-weight-600">Phone: </h6>
                    <p>+91 7776827177</p>
                  </div>
                </div>
                <div className="item bg-white">
                  <span className="icon">
                    <i className="fas fa-map-marker-alt text-primary" />
                  </span>
                  <div className="cont">
                    <h6 className="mb-1 font-weight-600">Address: </h6>
                    <p className="m-0">
                      Office no 6 , 2nd Floor Manogat Appt , Swarget,{" "}
                    </p>
                    <p className="m-0">Pune , Maharashtra-411009</p>
                  </div>
                </div>
                <div className="item bg-white">
                  <span className="icon">
                    <i className="fas fa-envelope text-primary" />
                  </span>
                  <div className="cont">
                    <h6 className="mb-1 font-weight-600">Email: </h6>
                    <p>info@shekruweb.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ECommerce;
